﻿.streetmap {
    height: 50vh;
    min-height: 500px;

    .leaflet-popup-content {
        font-size: .875rem;
        line-height: 1.5;

        p {
            margin: 0 5px 0 0;
        }

        .btn {
            font-weight: normal;
            padding: .5rem 1.5rem;
            color: #fff;
        }
    }

    .leaflet-container a {
        -webkit-tap-highlight-color: initial;
        color: initial;
    }
}
