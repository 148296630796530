footer {
    background: #272624;
    color: rgba(255,255,255,.8);
    font-size: .85rem;

    a {
        color: rgba(255,255,255,.8);

        &:hover {
            color: rgba(255,255,255,.8);
        }
    }

    .footer-content {
        padding: 40px 15px;
    }


    .logo {
        display: block;
        margin-bottom: 10px;
        opacity: .8;
    }

    .fab{
        font-size:1.5rem;
    }

    #copyright {
        background: #1d1c1b;
        text-align: center;
        padding: 10px 0px;
        font-size: 0.75rem;

        a {
            color: #757575;
        }
    }
}

@include media-breakpoint-down(md) {
    footer{
        .footer-content{
            padding:30px 15px;
        }
    }
}
