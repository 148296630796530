a {
    color: $primary;
}

h1, h2 {
    text-transform: uppercase;
}

figcaption {
    font-size: 0.875rem;
    color: #777;
}

.text-white {
    a {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

@include media-breakpoint-down(md) {
    h1, .h1 {
        font-size: 2.75rem;
    }

    h2, .h2 {
        font-size: 2rem;
    }
    h3, .h3{
        font-size:1.3rem;
    }
}
