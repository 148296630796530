header {
    width: 100vw;
    z-index: 1020;
    background: #272624;
    height: 80px;
}

#logo {
    position: relative;
    z-index: 20;
    top: 15px;
}

@include media-breakpoint-down(md) {
    header {
        height: 60px;

        .container {
            max-width: 100%;
        }
    }

    #top {
        border: none;
        padding: 10px 0;
    }

    #logo {
        top: 7px;
        left: 15px;

        img {
            width: 180px;
        }
    }
}
