.basm-container {
    position: fixed;
    top: 17px;
    right: 80px;
    z-index: 999;
    display: flex;
    gap: 0.25rem;

    .basm-btn {
        display: flex;
        width: 3.25rem;
        height: 3.25rem;
        padding: .375rem .75rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
        color: #fff;

        svg {
            width: 1.75rem;
            height: 1.75rem;
        }
    }

    .basm_basket_btn {
        position: relative;

        .basket-counter {
            position: absolute;
            top: 0;
            right: 0;
            width: 1.35rem;
            height: 1.35rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background-color: $primary;
            color: white;
            font-weight: 700;
        }
    }
}

@include media-breakpoint-down(md) {
    .basm-container {
        top: 7px;
        right: 60px;
        gap: 0;

        .basm-btn {
            height: 3rem;
            width: 3rem;
        }
    }
}
