﻿.concert-top{
    h1{
        max-width:400px;
    }
    .ticket-link{
        position:absolute;
        width:100%;
        bottom:30px;
    }
}

.concert-list {
    .concert-box {
        overflow:hidden;
        img {
            transform: scale(1);
            transition: all 1s ease;
        }

        &:hover {
            img {
                transform: scale(1.2);
            }
        }
    }

    .info {
        .btn {
            position: absolute;
            top: -30px;
        }
    }
}

.concert-info-text {
    color: white !important;
}

@include media-breakpoint-down(md) {
    .concert-top {
        h1 {
            max-width: 400px;
        }

        .ticket-link {
            position: relative;
            width:auto;
        }
    }
}