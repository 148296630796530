$font-family-base: 'Lato', sans-serif;
$headings-font-family: 'Raleway', sans-serif;

$primary: #666600;
$secondary: #1d4835;

$theme-colors: ( 
    "dark-green": #1d4835,
    "green": #2e654e,
    "dark-brown": #4a433b,
    "antique-bronze": #666600,
    "ash-gray": #a5ae9e,
    "ash-gray-light": #cbd5c3,
    "orange": #ad6c28,
    "black": #000,
    
);

$body-color: #1c2129;
$body-bg: #f5f7f3;

$h1-font-size: 3rem;
$h2-font-size: 2.6rem;
$h3-font-size: 2.1rem;

$headings-font-weight: 800;

$lead-font-size:1.5rem;

$link-color: $secondary;

$input-box-shadow: none;

$border-radius: 0px;
$border-radius-lg: 0px;
$border-radius-sm: 0px;

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1400px, xxl: 1600px );

$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl: 1400px );

@import "../../node_modules/bootstrap/scss/variables";
