﻿main{
    padding-top:80px;
}

@include media-breakpoint-down(md) {
    main {
        padding-top: 60px;
    }
    .container-fluid{
        padding:0;
        overflow:hidden;
    }
}