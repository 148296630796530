﻿.expand-image {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}

.content-overlap{
    position:absolute;
    z-index:2;
    bottom:20px;
}

@include media-breakpoint-down(md) {
    .content-overlap {
        position:relative;
        bottom:initial;
    }
}