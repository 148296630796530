.hero {
    position: relative;

    .hero-video-container {
        width: 100%;
        height: 90vh;
        aspect-ratio: 16/9;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .hero-content {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;

        .hero-actions {
            margin-top: 2rem;
            display: flex;
        }
    }

    .hero-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .hero-primary, .hero-secondary {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        padding-top: 0.4em;
        padding-bottom: 0.4em;
        padding-left: 1em;
        padding-right: 1em;
        font-size: 1.6rem;
        text-transform: uppercase;
        margin-right: 1rem;
    }
}

.sticky-socials {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 11;

    .sticky-social {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;

        .sticky-social-icon-box {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 3px;
        }

        &:nth-child(1) {
            background-color: $primary;
        }

        &:nth-child(2) {
            background-color: $secondary;
        }
    }
}

@include media-breakpoint-down(md) {
    .hero {

        .hero-content {
            top: 30%;
        }

        .hero-video-container {
            height: 80vh;
        }

        .hero-primary {
            font-size: 1.4rem;
        }

        .hero-secondary {
            display: none;
        }

        h1 {
            font-size: 3rem;
        }
    }

    .sticky-socials {
        top: 80%;
    }
}

#slider {
    .swiper-slide {
        width: 90%;
        height: auto;

        h2 {
            font-size: 3rem;
            line-height: 1.1;
        }

        p {
            font-size: .9rem;

            &.date {
                font-size: 1.2rem;
            }
        }
    }


    div[class^="swiper-button-"] {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        z-index: 1;
        background: #fff;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 2rem;

        &.swiper-button-next {
            right: calc(10% - 50px);
            border-left: 1px solid #333;
        }

        &.swiper-button-prev {
            right: 10%;
        }
    }
}

@include media-breakpoint-down(md) {
    #slider {
        .swiper-slide {
            width: calc(100% - 50px);

            h2 {
                font-size: 1.8rem;
            }
        }

        div[class^="swiper-button-"] {
            &.swiper-button-next {
                right: 0;
            }

            &.swiper-button-prev {
                right: 50px;
            }
        }
    }
}
