#menu {
    background: rgba(74, 67, 59, .95);
    position: fixed;
    top: 0px;
    right: -100vw;
    width: 100%;
    height: 100vh;
    z-index: 10;
    transition: all 0.4s ease;
    overflow-y: scroll;

    .list-inline {
        width: 60%;
        margin: auto;
        position: absolute;
        top: 20vh;
        left: 0px;
        right: 0px;
    }

    .list-inline-item {
        display: block;
        clear: both;

        > a {
            font-size: 2.5rem;
            font-weight: 800;
            padding: 15px 0px;
            float: none;
            display: inline-block;
            color: #fff;

            &:hover {
                text-decoration: none;
            }
        }

        i {
            color: #fff;
            float: none;
            font-size: 2.4rem;
            margin-left: 20px;
            cursor: pointer;
            transition: all ease .3s;

            &.show-menu {
                transform: rotate(180deg);
                transition: all ease .3s;
            }
        }

        &.has-submenu {

            .submenu {
                margin-bottom: 30px;
                margin-left: 30px;

                a {
                    color: #fff;
                    display: inline-block;
                    text-transform: initial;
                    font-size: 1.8rem;
                    line-height: 1.8;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &.open {
        right: 0px;
        transition: all 0.4s ease;
    }
}

#nav-toggle {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 20;
    top: 30px;
    right: 30px;

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 50%;
        background: #fff;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .3s all;

        &:nth-child(even) {
            left: 50%;
        }

        &:nth-child(odd) {
            left: 0px;
        }

        &:nth-child(1), &:nth-child(2) {
            top: 0px;
        }

        &:nth-child(3), &:nth-child(4) {
            top: 11px;
        }

        &:nth-child(5), &:nth-child(6) {
            top: 23px;
        }
    }

    &.open {
        span {
            background: #fff;

            &:nth-child(1), &:nth-child(6) {
                transform: rotate(45deg);
            }

            &:nth-child(2), &:nth-child(5) {
                transform: rotate(-45deg);
            }

            &:nth-child(1) {
                left: 5px;
                top: 9px;
            }

            &:nth-child(2) {
                left: 15px;
                top: 9px;
            }

            &:nth-child(3) {
                left: -50%;
                opacity: 0;
            }

            &:nth-child(4) {
                left: 100%;
                opacity: 0;
            }

            &:nth-child(5) {
                left: 5px;
                top: 19px;
            }

            &:nth-child(6) {
                left: 15px;
                top: 19px;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    #nav-toggle {
        top: 17px;
        right: 20px;
    }

    #menu {
        .list-inline {
            width: 100%;
            padding: 0 50px;
        }

        .list-inline-item {
            > a {
                font-size: 2rem;
            }

            i {
                font-size: 2rem;
            }
        }
    }
}
