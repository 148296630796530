@import "../variables.scss";

.sponsor-list-heading {
    margin-top: 6rem;
    text-align: center;
}

.sponsor-list {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.sponsor {
    width: 440px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;

    img {
        max-width: 100%;
        object-fit: contain;
    }
}

.ribbon {
    position: absolute;
    clip-path: inset(0 -100%);
    text-transform: uppercase;
    inset: 0 0 auto auto;
    transform-origin: 0 0;
    transform: translate(29.3%) rotate(45deg);
}

.sponsor-apollo {
    border-color: map-get($theme-colors, "ash-gray");
    box-shadow: 0 10px 15px -3px rgba(165, 174, 158, 0.9), 0 4px 6px -4px rgba(165, 174, 158, 0.9);

    .ribbon {
        background-color: map-get($theme-colors, "ash-gray");
        box-shadow: 0 0 0 999px map-get($theme-colors, "ash-gray");
        color: #fff;
    }
}

.sponsor-avalon {
    border-color: map-get($theme-colors, "dark-green");
    box-shadow: 0 10px 15px -3px rgba(165, 174, 158, 0.9), 0 4px 6px -4px rgba(165, 174, 158, 0.9);

    .ribbon {
        background-color: map-get($theme-colors, "dark-green");
        box-shadow: 0 0 0 999px map-get($theme-colors, "dark-green");
        color: #fff;
    }
}

.sponsor-arena {
    box-shadow: 0 10px 15px -3px rgba(074, 067, 059, 0.9), 0 4px 6px -4px rgba(74, 67, 59, 0.9);

    .ribbon {
        background-color: map-get($theme-colors, "dark-brown");
        box-shadow: 0 0 0 999px map-get($theme-colors, "dark-brown");
        color: #fff;
    }
}

.sponsor-orange {
    /*border-color: map-get($theme-colors, "antique-bronze");*/
    box-shadow: 0 10px 15px -3px rgba(102, 102, 0, 0.9), 0 4px 6px -4px rgba(102, 102, 0, 0.9);
    /*grid-column: auto / span 2;*/

    .ribbon {
        background-color: map-get($theme-colors, "antique-bronze");
        box-shadow: 0 0 0 999px map-get($theme-colors, "antique-bronze");
        color: #fff;
    }
}

@include media-breakpoint-down(md) {
    .sponsor-list {
        grid-template-columns: repeat(1, 1fr);
    }

    .sponsor-orange {
        grid-column: auto;
    }
}
