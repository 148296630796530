#hero {
    position: relative;
    width: 100%;
    z-index: 0;
    max-height: 100vh;
    overflow: hidden;
}

.header-block{
    margin-top:-90px;
}

@include media-breakpoint-down(md) {
    .header-block {
        margin-top: -50px;
    }
}
